import { runAnimations } from '../components/animations';
import { sliders } from '../components/sliders';
import { deferImages } from '../helpers/defer';
import { mask, submitForm } from '../components/form';

$(document).ready(function() {
  deferImages();
  sliders();
  mask();
  submitForm();

  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = hash;
      });
    } // End if
  });

  if($(window).width() > 1100) {
    runAnimations();
  }
});