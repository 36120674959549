export const sliders = () => {
  $('.testimonials__slider').slick({
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    centerPadding: '0',
    dots: true
  });
};