export const mask = () => {
  $('form .tel').on('input', function(e) {
    $(this).val(mtel(e.target.value));
  });

  // $('form').on('submit', function(e) {
  //   e.preventDefault();

  //   submitForm($(this));
  // });
};

const mtel = (v) => {
  v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
  v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
  return v;
};

// const submitForm = (form) => {
//   var data = form.serialize().split("&");
//   var obj = {};

//   for(var key in data) {
//     console.log(data[key]);
//     obj[data[key].split("=")[0]] = data[key].split("=")[1];
//   }

//   console.log(obj);
// };

export const submitForm = () => {
  $('[name="contactForm"]').submit(function (e) {
    e.preventDefault();

    var nome = $(this).find('[name="nome"]').val();
    var email = $(this).find('[name="email"]').val();
    var telefone = $(this).find('[name="telefone"]').val();
    var mensagem = $(this).find('[name="mensagem"]').val();

    $.post("/scripts/form-novo-site.php", {
      nome: nome,
      email: email,
      telefone: telefone,
      mensagem: mensagem
    })
    .done(function() {
      $('[name="contactForm"] input').val('');
      $('[name="contactForm"] textarea').val('');
      
      $('[name="contactForm"]').find('.form-msg').remove();
      $('[name="contactForm"]').prepend('<p class="form-msg">Formulário enviado com sucesso!</p>');

      setTimeout(() => {
        $('[name="contactForm"]').find('.form-msg').remove();
      }, 5000);
    })
    .fail(function() {
      $('[name="contactForm"]').find('.form-msg').remove();
      $('[name="contactForm"]').prepend('<p class="form-msg">Houve um erro no envio, por favor tente novamente</p>');

      setTimeout(() => {
        $('[name="contactForm"]').find('.form-msg').remove();
      }, 5000);
    });
  });
};