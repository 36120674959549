let controller = new ScrollMagic.Controller();
const isFullHD = $(window).width() > 1700;

const isHDPlus = $(window).width() >= 1400;

const is2kPlus = $(window).width() >= 2200;

export const runAnimations = () => {
  timelineIntro();
  timelineZonasul();
  timeline99();
  timelineSantander();
  timelineTelecine();
  timelineCocacola();
  timelineVivara();
  timelineUnimarc();
  timelineBBlend();
  timelineRededor();
  timelineFGV();
  timelineContato();
};

const timelineIntro = () => {
  let tl_intro = new TimelineLite();
  tl_intro
    .fromTo(".hero-intro__video", 15, { top: "-=22%" }, { top: "+=50%" });
    
  let sceneIntro = new ScrollMagic.Scene({
    triggerElement: ".section--geometric-red", 
    duration: 1000,
    triggerHook: 1
  })
  // .addIndicators({ name: "Intro Timeline", colorTrigger: "green", colorStart: "green", colorEnd: "green" })
  .setTween(tl_intro)
  .addTo(controller);
};

const timelineZonasul = () => {
  let tl_zonasul = new TimelineLite();
  tl_zonasul
    .fromTo("[data-companie='zonasul']", 15, { backgroundPositionY: is2kPlus ? "160%" : isFullHD ? "1000%" : "-=120%" }, { backgroundPositionY: is2kPlus ? "30%" : isFullHD ?  "-300%" : "+=200%" })
    .fromTo("[data-companie='zonasul'] .phone", 20, { top: "30%" }, { top: "70%" }, 2);
    
  let sceneZonasul = new ScrollMagic.Scene({
    triggerElement: "[data-companie='zonasul']", 
    duration: 3200,
    triggerHook: 1
  })
  // .addIndicators({ name: "Zonasul Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_zonasul)
  .addTo(controller);
};

const timeline99 = () => {
  let tl_99 = new TimelineLite();
  tl_99
    .fromTo("[data-companie='99']", 15, { backgroundPositionY: isFullHD ? "-=80%" : isHDPlus ? "-=75%" : "-=70%" }, { backgroundPositionY: "+=140%" })
    .fromTo("[data-companie='99'] .tablet", 15, { top: "15%" }, { top: "45%" }, 1)
    .fromTo("[data-companie='99'] .phone:not(.tablet)", 15, { top: "60%" }, { top: "90%" }, 1.5);

  let scene99 = new ScrollMagic.Scene({
    triggerElement: "[data-companie='99']", 
    duration: 3300,
    triggerHook: 1
  })
  // .addIndicators({ name: "99 Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_99)
  .addTo(controller);
};

const timelineSantander = () => {
  let tl_santander = new TimelineLite();
  tl_santander
    .fromTo("[data-companie='santander']", 15, { backgroundPositionY: is2kPlus ? "-=20%" : isFullHD ? "-=80%" : "-=70%" }, { backgroundPositionY: "+=140%" });

  let sceneSantander = new ScrollMagic.Scene({
    triggerElement: "[data-companie='santander']", 
    duration: 3000,
    triggerHook: 1
  })
  // .addIndicators({ name: "Santander Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_santander)
  .addTo(controller);
};

const timelineTelecine = () => {
  let tl_telecine = new TimelineLite();
  tl_telecine
    .fromTo("[data-companie='telecine']", 15, { backgroundPositionY: is2kPlus ? "40%" : isFullHD ? "200%" : isHDPlus ? "290%" : "340%" }, { backgroundPositionY: is2kPlus ? "70%" : isFullHD ? "120%" : isHDPlus ? "140%" :"158%" })
    .fromTo("[data-companie='telecine'] .icon-hero", 15, { bottom: "-10%" }, { bottom: "60%" }, 1)
    .fromTo("[data-companie='telecine'] .icon-olaf", 15, { top: "5%" }, { top: "50%" }, 1)
    .fromTo("[data-companie='telecine'] .parallax__img:nth-child(2)", 10, { left: "-20%" }, { left: "0%" }, 1.5)
    .fromTo("[data-companie='telecine'] .parallax__img:nth-child(3)", 10, { right: "-20%" }, { right: "0%" }, 1.5);

  let sceneTelecine = new ScrollMagic.Scene({
    triggerElement: "[data-companie='telecine']", 
    duration: is2kPlus ? 4000 : isFullHD ? 3000 : 2800,
    triggerHook: 1
  })
  // .addIndicators({ name: "Telecine Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_telecine)
  .addTo(controller);
};

const timelineCocacola = () => {
  let tl_cocacola = new TimelineLite();
  tl_cocacola
    .fromTo("[data-companie='cocacola']", 15, { backgroundPositionY: "-=80%" }, { backgroundPositionY: "+=120%" })
    .fromTo("[data-companie='cocacola'] .ico-garrafa-coca", 15, { right: "60px" }, { right: "-60px" }, 3)
    .fromTo("[data-companie='cocacola'] .parallax__img:nth-child(2)", 15, { right: "-20%" }, { right: "2%" }, 4)
    .fromTo("[data-companie='cocacola'] .parallax__img:nth-child(3)", 15, { right: "-20%" }, { right: "2%" }, 5)
    .fromTo("[data-companie='cocacola'] .ico-bone-coca", 15, { left: "-5%" }, { left: "25%" }, 8);

  let sceneCocacola = new ScrollMagic.Scene({
    triggerElement: "[data-companie='cocacola']", 
    duration: 3000,
    triggerHook: 1
  })
  // .addIndicators({ name: "Cocacola Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_cocacola)
  .addTo(controller);
};

const timelineVivara = () => {
  let tl_vivara = new TimelineLite();
  tl_vivara
    .fromTo("[data-companie='vivara']", 15, { backgroundPositionY: isFullHD ? "-=40%" : "-=50%" }, { backgroundPositionY: "+=80%" });

  let sceneVivara = new ScrollMagic.Scene({
    triggerElement: "[data-companie='vivara']", 
    duration: 2500,
    triggerHook: 1
  })
  // .addIndicators({ name: "Vivara Timeline", colorTrigger: "green", colorStart: "blue", colorEnd: "red" })
  .setTween(tl_vivara)
  .addTo(controller);
};

const timelineUnimarc = () => {
  let tl_unimarc = new TimelineLite();
  tl_unimarc
    .fromTo("[data-companie='unimarc']", 15, { backgroundPositionY: isHDPlus ? "-=90%" : "-=100%" }, { backgroundPositionY: "+=150%" })
    .fromTo("[data-companie='unimarc'] .phone", 10, { top: "90%" }, { top: "35%" }, 2)
    .fromTo("[data-companie='unimarc'] .parallax__img:nth-child(2)", 15, { top: "-300px" }, { top: "-110px" }, 3);
    
  let sceneUnimarc = new ScrollMagic.Scene({
    triggerElement: "[data-companie='unimarc']", 
    duration: 3000,
    triggerHook: 1
  })
  // .addIndicators({ name: "Unimarc Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_unimarc)
  .addTo(controller);
};

const timelineBBlend = () => {
  let tl_bblend = new TimelineLite();
  tl_bblend
    .fromTo("[data-companie='bblend']", 15, { backgroundPositionY: is2kPlus ? "-=20%" : "-=120%" }, { backgroundPositionY: "+=210%" })
    .fromTo("[data-companie='bblend'] .ico-capsula-1", 15, { bottom: "-=120px" }, { bottom: "+=150px" }, 3)
    .fromTo("[data-companie='bblend'] .ico-capsula-2", 15, { top: "+=35%" }, { top: "-=60%" }, 2)
    .fromTo("[data-companie='bblend'] .phone", 10, { top: "90%" }, { top: "50%" }, 5);
    // .fromTo("[data-companie='bblend'] .parallax__img:nth-child(2)", 15, { top: "-300px" }, { top: "-110px" }, 3);
    
  let sceneBBlend = new ScrollMagic.Scene({
    triggerElement: "[data-companie='bblend']", 
    duration: 3000,
    triggerHook: 1
  })
  // .addIndicators({ name: "BBlend Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_bblend)
  .addTo(controller);
};

const timelineRededor = () => {
  let tl_rededor = new TimelineLite();
  tl_rededor
    .fromTo("[data-companie='rededor']", 15, { backgroundPositionY: is2kPlus ? "415%" : isFullHD ? "-=90%" : isHDPlus ? "-=80%" : "-=70%" }, { backgroundPositionY: is2kPlus ? "-1715%" : "+=140%" })
    .fromTo("[data-companie='rededor'] .phone", 15, { top: "90%" }, { top: "50%" }, 5);
    // .fromTo("[data-companie='rededor'] .parallax__img:nth-child(2)", 15, { top: "-300px" }, { top: "-110px" }, 3);
    
  let sceneRededor = new ScrollMagic.Scene({
    triggerElement: "[data-companie='rededor']", 
    duration: 3000,
    triggerHook: 1
  })
  // .addIndicators({ name: "Rededor Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_rededor)
  .addTo(controller);
};

const timelineFGV = () => {
  let tl_fgv = new TimelineLite();
  tl_fgv
    .fromTo("[data-companie='fgv']", 15, { backgroundPositionY: is2kPlus ? "-=500%" : isFullHD ? "-=140%" : isHDPlus ? "-=90%" : "-=100%" }, { backgroundPositionY: is2kPlus ? "+=500%" : isFullHD ? "+=200%" : isHDPlus ? "+=135%" : "+=160%" }, 1)
    .fromTo("[data-companie='fgv'] .phone", 15, { bottom: "-300px" }, { bottom: "-50px" }, 2);

  let sceneFGV = new ScrollMagic.Scene({
    triggerElement: "[data-companie='fgv']", 
    duration: 2100,
    triggerHook: 1
  })
  // .addIndicators({ name: "FGV Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_fgv)
  .addTo(controller);
};

const timelineContato = () => {
  let tl_contato = new TimelineLite();
  tl_contato
    .fromTo(".section--form .airplane--left", 20, { left: "-=5%" }, { left: "+=20%" }, 8)
    .fromTo(".section--form .airplane--right", 20, { right: "-=5%" }, { right: "+=15%" }, "-=8");

  let sceneContato = new ScrollMagic.Scene({
    triggerElement: ".section--form", 
    duration: 600,
    triggerHook: 1
  })
  // .addIndicators({ name: "Contato Timeline", colorTrigger: "white", colorStart: "white", colorEnd: "white" })
  .setTween(tl_contato)
  .addTo(controller);
};